import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthData {
  userDetails: UserTypes;
  adminDetails: AdminUserTypes;
  users: UserTypes[];
}

const initialState: AuthData = {
  userDetails: null,
  adminDetails: null,
  users: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserTypes>) => {
      state.userDetails = action.payload;
    },
    setAdminDetails: (state, action: PayloadAction<AdminUserTypes>) => {
      state.adminDetails = action.payload;
    },
    setUsers: (state, action: PayloadAction<UserTypes[]>) => {
      state.users = action.payload;
    },
  },
});

export const { setUserDetails, setAdminDetails, setUsers } = authSlice.actions;
export default authSlice.reducer;
