import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LanguageData {
  currentLanguage: string;
  availableLanguages: AvailableLanguageTypes[];
  languageTranslations: LanguageTranslationsTypes;
}

const initialState: LanguageData = {
  currentLanguage: "en",
  availableLanguages: [],
  languageTranslations: null,
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setCurrentLanguage: (state, action: PayloadAction<string>) => {
      state.currentLanguage = action.payload;
    },
    setAvailabeLanguages: (state, action: PayloadAction<AvailableLanguageTypes[]>) => {
      state.availableLanguages = action.payload;
    },
    setLanguageTranslations: (state, action: PayloadAction<LanguageTranslationsTypes>) => {
      state.languageTranslations = action.payload;
    },
  },
});

export const { setCurrentLanguage, setAvailabeLanguages, setLanguageTranslations } = languageSlice.actions;
export default languageSlice.reducer;
