import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenData {
  activeRFTTab: number;
  activeCampaignTab: number;
  tokenData: NFTItemType;
  selectedOwnerData: UserTypes;
  selectedCampaign: CampaignTypes;
  campaignInitiator: CampaignInitiatorsTypes;
}

const initialState: TokenData = {
  activeRFTTab: 0,
  activeCampaignTab: 0,
  tokenData: null,
  selectedOwnerData: null,
  selectedCampaign: null,
  campaignInitiator: null,
};

const tokenSlice = createSlice({
  name: "rft",
  initialState,
  reducers: {
    setTokenData: (state, action: PayloadAction<NFTItemType>) => {
      state.tokenData = action.payload;
    },
    setSelectedOwnerData: (state, action: PayloadAction<UserTypes>) => {
      state.selectedOwnerData = action.payload;
    },
    setActiveRFTTab: (state, action: PayloadAction<number>) => {
      state.activeRFTTab = action.payload;
    },
    setActiveCampaignTab: (state, action: PayloadAction<number>) => {
      state.activeCampaignTab = action.payload;
    },
    setSelectedCampaign: (state, action: PayloadAction<CampaignTypes>) => {
      state.selectedCampaign = action.payload;
    },
    setCampaignInitiator: (state, action: PayloadAction<CampaignInitiatorsTypes>) => {
      state.campaignInitiator = action.payload;
    },
  },
});

export const {
  setTokenData,
  setActiveRFTTab,
  setSelectedCampaign,
  setSelectedOwnerData,
  setCampaignInitiator,
  setActiveCampaignTab,
} = tokenSlice.actions;

export default tokenSlice.reducer;
