import { FC } from "react";
import loaderGif from "assets/images/loader.gif";

const PageLoader: FC = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center relative bg-ivoryWhite">
      {/* <img src={loaderGif} alt="" className="w-1/4 md:w-1/6 object-contain" /> */}
      <div className="loader absolute max-w-60 w-full h-auto p-3 aspect-square rounded-full bg-green6 animate-loader" />

      {/* tree svg image */}
      <svg viewBox="0 0 512 512" className="max-w-40 w-full h-auto">
        <path
          d="M249.853,375.827c-2.167,29.707-15.604,70.771-70.275,86.037c-1.89,0.528-3.255,2.495-3.255,4.794l0,0  c0,2.724,1.865,4.932,4.167,4.932h50.866c9.38,0,17.157-7.264,17.795-16.622l4.878-71.449  C254.244,380.362,252.617,377.365,249.853,375.827L249.853,375.827z"
          fill="#89563B"
        />
        <path
          d="M317.199,411.407c0,0,20.235,38.302,65.647,43.237c1.829,0.198,3.241,1.697,3.241,3.538l0,0  c0,1.97-1.596,3.566-3.565,3.566h-41.477c-13.17,0-23.847-10.677-23.847-23.847V411.407z"
          fill="#89563B"
        />
        <path
          d="M171.474,267.195l4.914,31.044c1.959,12.38,10.678,22.52,22.516,26.639  c17.909,6.231,42.453,19.155,46.001,43.758l-4.164-56.397c0,0-54.047,4.92-53.519-49.586L171.474,267.195z"
          fill="#89563B"
        />
        <path
          d="M376.217,266.171l-3.376,27.494c-1.347,10.964-8.716,20.163-19.016,24.153  c-15.583,6.039-36.806,18.173-39.177,39.952l1.938-49.804c0,0,47.756,2.678,45.62-45.314L376.217,266.171z"
          fill="#89563B"
        />
        <path
          d="M227.794,254.814c0,0,22.84,92.73,12.221,197.318c-0.78,7.68-3.53,15.054-8.04,21.318  c-5.475,7.604-14.699,16.418-29.77,21.531c-4.025,1.365-4.149,7.034-0.201,8.609c12.376,4.936,33.317,8.672,52.769-9.694  c4.698-4.437,10.624-7.499,17.06-8.083c1.371-0.125,2.813-0.168,4.308-0.095c5.858,0.286,11.413,2.681,15.974,6.37  c6.35,5.135,23.156,16.621,43.928,14.137c3.277-0.393,4.538-4.551,2.088-6.762c-4.023-3.63-5.482-5.567-10.074-13.092  c0,0,32.537,9.447,56.581,0.786c4.202-1.514,3.954-7.532-0.339-8.768c-15.646-4.501-43.166-15.433-56.664-38.921  c-3.613-6.286-5.376-13.439-5.666-20.684c-1.127-28.146-4.154-119.84,2.269-161.586L227.794,254.814z"
          fill="#CE9E7C"
        />
        <path
          d="M294.815,290.002c0,0,10.294,7.416,26.13,8.014c0.591-15.653,1.618-29.937,3.292-40.814l-96.443-2.387  c0,0,3.43,13.965,7.013,37.104C247.576,298.016,276.016,303.417,294.815,290.002z"
          fill="#89563B"
        />
        <path
          d="M271.83,457.355c-3.754,24.598-17.056,36.542-17.056,36.542c4.698-4.437,10.624-7.498,17.06-8.084  c1.371-0.124,2.813-0.167,4.308-0.095c5.858,0.287,11.413,2.682,15.974,6.371c-10.457-9.35-15.355-24.512-17.58-34.865  C274.209,455.715,272.063,455.828,271.83,457.355z"
          fill="#89563B"
        />
        <path
          d="M363.747,490.764c-19.286,0.223-32.326-18.892-41.827-27.338c-1.332-1.185-5.293-1.563-3.471,3.123  c4.549,11.699,9.607,19.824,9.607,19.824S345.172,491.336,363.747,490.764z"
          fill="#89563B"
        />
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="SVGID_1_"
          x1="255.9995"
          x2="255.9995"
          y1="93.2827"
          y2="373.0491"
        >
          <stop offset="0" style={{ stopColor: "#BFC29F" }} />
          <stop offset="1" style={{ stopColor: "#9B9B6E" }} />
        </linearGradient>
        <path
          d="M204.784,256.697c0,0,52.302,46.938,113.991,6.705c0,0,52.303,32.857,87.17-11.398  c0,0,35.424,0.514,46.975-36.643c3.415-10.986,3.939-22.708,1.826-34.018c-2.654-14.207-9.967-34.438-30.025-44.672  c0,0,20.116-33.527,0-69.065c0,0-21.457-39.562-69.736-29.503c0,0-36.879-42.244-114.662-32.186c0,0-65.713,4.023-97.899,51.631  c0,0-25.145-1.863-44.78,21.187c-8.134,9.548-13.089,21.402-14.852,33.82c-1.721,12.127-1.438,28.089,8.671,38.197  c0,0-7.098,1.589-15.146,6.367c-37.157,22.061-19.8,79.712,23.385,78.171c0.379-0.014,0.762-0.03,1.148-0.05  c0,0,2.486,25.855,34.434,36.463c10.979,3.645,22.757,4.156,34.144,2.113C180.938,271.753,195.854,267.115,204.784,256.697z"
          fill="url(#SVGID_1_)"
        />
        <path
          d="M454.746,181.343c-2.654-14.207-9.967-34.438-30.025-44.672c0,0,20.116-33.526,0-69.065  c0,0-10.263-18.9-32.544-27.25c11.653,9.58,26.728,27.593,22.485,54.741c0,0-4.169,22.818-25.254,32.876  c0.02-0.143,0.052-0.282,0.068-0.426c1.881-16.636-8.938-31.09-24-34.923c-3.014-0.766-5.471,2.422-4.219,5.268  c1.633,3.708,2.564,7.754,2.129,11.966c-2.162,20.905-17.625,28.347-26.375,30.899c-2.809,0.819-3.753,4.36-1.633,6.375  c6.674,6.339,16.061,9.847,26.246,8.584c10.037-1.244,18.727-7.302,23.675-15.727c5.569,10.277,12.659,28.667,7.392,49.69  c-5.631,22.478-24.316,39.063-46.856,44.436c-10.358,2.469-23.014,3.746-36.79,1.376c-7.982-1.374-15.646,3.568-18.238,11.242  c-3.947,11.688-14.066,26.504-39.641,31.542c19.076,3.426,42.569,1.458,67.609-14.873c0,0,52.303,32.857,87.17-11.398  c0,0,35.424,0.514,46.975-36.643C456.335,204.375,456.859,192.653,454.746,181.343z"
          fill="#7F7D56"
        />
        <path
          d="M218.475,147.431c-3.013-0.767-5.471,2.422-4.218,5.268c1.633,3.708,2.564,7.754,2.128,11.966  c-2.162,20.905-17.625,28.347-26.375,30.899c-2.808,0.819-3.752,4.36-1.631,6.375c6.674,6.339,16.06,9.847,26.245,8.584  c14.507-1.799,26.209-13.643,27.852-28.168C244.356,165.718,233.537,151.264,218.475,147.431z"
          fill="#7F7D56"
        />
        <path
          d="M267.77,316.898c-2.479-0.161-4.457,0.713-5.889,2.134c-4.244,2.804-6.706,7.762-3.059,12.271  c2.123,2.624,4.725,3.566,4.807,7.385c0.068,3.144-0.343,5.078,1.934,7.608c2.344,2.606,7.74,0.687,7.332-3.038  c-0.107-0.975-0.375-2.563-0.564-4.268c0.275-0.381,0.514-0.813,0.684-1.314c0.73-2.15,1.881-3.316,2.959-5.225  c1.25-2.213,1.094-4.706,0.476-7.115C275.377,321.154,272.393,317.2,267.77,316.898z"
          fill="#89563B"
        />
        <path
          d="M307.322,327.577c-0.027-1.284-0.283-2.583-0.695-3.833c-0.151-1.674-0.677-3.325-1.732-4.785  c-3.786-5.236-11.076-4.144-15.336-0.162c-1.528,1.429-2.343,2.959-2.709,4.493c-1.37,2.853-1.281,5.613,0.199,8.503  c1.279,2.5,3.365,4.602,5.988,5.606c0.788,0.945,1.505,1.949,1.793,3.243c0.654,2.938,5.241,3.633,6.608,0.863  c0.616-1.248,0.88-2.314,0.958-3.551c0.727-1.099,1.23-2.061,2.141-3.141C306.291,332.733,307.383,330.359,307.322,327.577z"
          fill="#89563B"
        />
        <path
          d="M270.319,379.639c0.916,21.236,0.439,42.41,0.472,63.645c0.004,2.507,3.767,2.486,3.879,0  c0.958-21.188-0.368-42.529-2.068-63.645C272.484,378.193,270.256,378.156,270.319,379.639L270.319,379.639z"
          fill="#89563B"
        />
        <path
          d="M315.388,452.683c-1.573-7.575-4.488-14.779-5.78-22.487c-1.134-6.756-0.349-14.838-3.488-20.985  c-0.779-1.527-3.01-1.021-3.477,0.454c-2.173,6.867,0.346,16.397,1.891,23.366c1.603,7.224,4.135,15.194,9.711,20.318  C314.76,453.823,315.527,453.353,315.388,452.683L315.388,452.683z"
          fill="#89563B"
        />
        <path
          d="M302.641,396.873c3.77,0,3.775-5.857,0-5.857C298.871,391.016,298.865,396.873,302.641,396.873  L302.641,396.873z"
          fill="#89563B"
        />
        <circle cx="267.485" cy="327.163" fill="#FFFFFF" r="6.033" />
        <circle cx="297.144" cy="327.163" fill="#FFFFFF" r="6.032" />
      </svg>
    </div>
  );
};

export default PageLoader;
