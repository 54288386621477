import { mainnet, polygon } from "viem/chains";
import { createConfig, http } from "wagmi";
import { injected, coinbaseWallet, walletConnect } from "wagmi/connectors";

const config = createConfig({
  chains: [mainnet, polygon],
  multiInjectedProviderDiscovery: false,
  transports: {
    [mainnet.id]: http(),
    [polygon.id]: http(),
  },
  connectors: [
    injected({ target: "metaMask" }),
    coinbaseWallet({ appName: "Fund The Planet" }),
    walletConnect({ projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID }),
  ],
});

export default config;
