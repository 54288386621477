import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentData } from "firebase/firestore";

interface ToggleData {
  openSheet: boolean;
  openRFTTabs: boolean;
  toggleSounds: boolean;
  openForSaleRFTs: boolean;
  openUserSidebar: boolean;
  openGenesisTabs: boolean;
  openCampaignTabs: boolean;
  openAboutUsModal: boolean;
  openFoundersTabs: boolean;
  openProtectedRFTs: boolean;
  openBottomFilters: boolean;
  openFilterByPrice: boolean;
  openRFTImageModal: boolean;
  openShareLinkModal: boolean;
  openPropertiesModal: boolean;
  openForSaleInfoModal: boolean;
  openConnectWalletModal: boolean;
  openNotificationsModal: boolean;
  openLoginWithEmailModal: boolean;
  openSignUpWithEmailModal: boolean;
  toggleMeterKgToFeetPounds: boolean;
  toggleRescuerNameWalletAddress: boolean;
  openFilterByPricePerSquareMeter: boolean;
  openClaimRFTShopifyStepFormModal: boolean;
  openSecretURLTemporaryProfileStepFormModal: boolean;
  openSecretURLPermanentProfileStepFormModal: boolean;
  temporaryProtectorDetails: TemporaryProtectorTypes | DocumentData;
}

const initialState: ToggleData = {
  openSheet: true,
  openRFTTabs: false,
  toggleSounds: false,
  openGenesisTabs: false,
  openUserSidebar: false,
  openForSaleRFTs: false,
  openFoundersTabs: true,
  openCampaignTabs: false,
  openAboutUsModal: false,
  openProtectedRFTs: false,
  openBottomFilters: false,
  openRFTImageModal: false,
  openFilterByPrice: false,
  openShareLinkModal: false,
  openPropertiesModal: false,
  openForSaleInfoModal: false,
  openNotificationsModal: false,
  openConnectWalletModal: false,
  openLoginWithEmailModal: false,
  temporaryProtectorDetails: null,
  openSignUpWithEmailModal: false,
  toggleMeterKgToFeetPounds: false,
  toggleRescuerNameWalletAddress: false,
  openFilterByPricePerSquareMeter: false,
  openClaimRFTShopifyStepFormModal: false,
  openSecretURLTemporaryProfileStepFormModal: false,
  openSecretURLPermanentProfileStepFormModal: false,
};

const toggleSlice = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    setOpenSheet: (state, action: PayloadAction<boolean>) => {
      state.openSheet = action.payload;
    },
    setOpenRFTTabs: (state, action: PayloadAction<boolean>) => {
      state.openRFTTabs = action.payload;
    },
    setToggleSounds: (state, action: PayloadAction<boolean>) => {
      state.toggleSounds = action.payload;
    },
    setOpenForSaleRFTs: (state, action: PayloadAction<boolean>) => {
      state.openForSaleRFTs = action.payload;
    },
    setOpenUserSidebar: (state, action: PayloadAction<boolean>) => {
      state.openUserSidebar = action.payload;
    },
    setOpenGenesisTabs: (state, action: PayloadAction<boolean>) => {
      state.openGenesisTabs = action.payload;
    },
    setOpenAboutUsModal: (state, action: PayloadAction<boolean>) => {
      state.openAboutUsModal = action.payload;
    },
    setOpenFoundersTabs: (state, action: PayloadAction<boolean>) => {
      state.openFoundersTabs = action.payload;
    },
    setOpenProtectedRFTs: (state, action: PayloadAction<boolean>) => {
      state.openProtectedRFTs = action.payload;
    },
    setOpenBottomFilters: (state, action: PayloadAction<boolean>) => {
      state.openBottomFilters = action.payload;
    },
    setOpenFilterByPrice: (state, action: PayloadAction<boolean>) => {
      state.openFilterByPrice = action.payload;
    },
    setOpenRFTImageModal: (state, action: PayloadAction<boolean>) => {
      state.openRFTImageModal = action.payload;
    },
    setOpenShareLinkModal: (state, action: PayloadAction<boolean>) => {
      state.openShareLinkModal = action.payload;
    },
    setOpenPropertiesModal: (state, action: PayloadAction<boolean>) => {
      state.openPropertiesModal = action.payload;
    },
    setOpenForSaleInfoModal: (state, action: PayloadAction<boolean>) => {
      state.openForSaleInfoModal = action.payload;
    },
    setOpenConnectWalletModal: (state, action: PayloadAction<boolean>) => {
      state.openConnectWalletModal = action.payload;
    },
    setOpenNotificationsModal: (state, action: PayloadAction<boolean>) => {
      state.openNotificationsModal = action.payload;
    },
    setOpenLoginWithEmailModal: (state, action: PayloadAction<boolean>) => {
      state.openLoginWithEmailModal = action.payload;
    },
    setOpenSignUpWithEmailModal: (state, action: PayloadAction<boolean>) => {
      state.openSignUpWithEmailModal = action.payload;
    },
    setToggleMeterKgToFeetPounds: (state, action: PayloadAction<boolean>) => {
      state.toggleMeterKgToFeetPounds = action.payload;
    },
    setToggleRescuerNameWalletAddress: (state, action: PayloadAction<boolean>) => {
      state.toggleRescuerNameWalletAddress = action.payload;
    },
    setOpenFilterByPricePerSquareMeter: (state, action: PayloadAction<boolean>) => {
      state.openFilterByPricePerSquareMeter = action.payload;
    },
    setOpenClaimRFTShopifyStepFormModal: (state, action: PayloadAction<boolean>) => {
      state.openClaimRFTShopifyStepFormModal = action.payload;
    },
    setOpenSecretURLTemporaryProfileStepFormModal: (state, action: PayloadAction<boolean>) => {
      state.openSecretURLTemporaryProfileStepFormModal = action.payload;
    },
    setOpenSecretURLPermanentProfileStepFormModal: (state, action: PayloadAction<boolean>) => {
      state.openSecretURLPermanentProfileStepFormModal = action.payload;
    },
    setTemporaryProtectorDetails: (state, action: PayloadAction<TemporaryProtectorTypes | DocumentData>) => {
      state.temporaryProtectorDetails = action.payload;
    },
    setOpenCampaignTabs: (state, action: PayloadAction<boolean>) => {
      state.openCampaignTabs = action.payload;
    },
  },
});

export const {
  setOpenSheet,
  setOpenRFTTabs,
  setToggleSounds,
  setOpenForSaleRFTs,
  setOpenUserSidebar,
  setOpenGenesisTabs,
  setOpenFoundersTabs,
  setOpenAboutUsModal,
  setOpenCampaignTabs,
  setOpenFilterByPrice,
  setOpenRFTImageModal,
  setOpenProtectedRFTs,
  setOpenBottomFilters,
  setOpenShareLinkModal,
  setOpenPropertiesModal,
  setOpenForSaleInfoModal,
  setOpenNotificationsModal,
  setOpenConnectWalletModal,
  setOpenLoginWithEmailModal,
  setOpenSignUpWithEmailModal,
  setTemporaryProtectorDetails,
  setToggleMeterKgToFeetPounds,
  setToggleRescuerNameWalletAddress,
  setOpenFilterByPricePerSquareMeter,
  setOpenClaimRFTShopifyStepFormModal,
  setOpenSecretURLTemporaryProfileStepFormModal,
  setOpenSecretURLPermanentProfileStepFormModal,
} = toggleSlice.actions;

export default toggleSlice.reducer;
