import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ChatGPTData {
  recentSearches: ChatGPTRecentSearchesTypes[];
}

const initialState: ChatGPTData = {
  recentSearches: [],
};

const chatGPTSlice = createSlice({
  name: "chatGPT",
  initialState,
  reducers: {
    setRecentSearches: (state, action: PayloadAction<ChatGPTRecentSearchesTypes[]>) => {
      state.recentSearches = action.payload;
    },
  },
});

export const { setRecentSearches } = chatGPTSlice.actions;
export default chatGPTSlice.reducer;
