import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NFTsData {
  NFTOwners: NFTItemType[];
  NFTTransfers: NFTTransfersType[];
  openLoadingScreenImages: boolean;
  currencyExchangeRates: ExchangeRatesTypes;
  campaigns: CampaignTypes[];
}

const initialState: NFTsData = {
  NFTOwners: [],
  NFTTransfers: [],
  currencyExchangeRates: null,
  openLoadingScreenImages: true,
  campaigns: [],
};

const rftSlice = createSlice({
  name: "rft",
  initialState,
  reducers: {
    setNFTOwners: (state, action: PayloadAction<NFTItemType[]>) => {
      state.NFTOwners = action.payload;
    },
    setNFTTransfers: (state, action: PayloadAction<NFTTransfersType[]>) => {
      state.NFTTransfers = action.payload;
    },
    setCurrencyExchangeRates: (state, action: PayloadAction<ExchangeRatesTypes>) => {
      state.currencyExchangeRates = action.payload;
    },
    setOpenLoadingScreenImages: (state, action: PayloadAction<boolean>) => {
      state.openLoadingScreenImages = action.payload;
    },
    setCampaigns: (state, action: PayloadAction<CampaignTypes[]>) => {
      state.campaigns = action.payload;
    },
  },
});

export const { setNFTOwners, setNFTTransfers, setCurrencyExchangeRates, setOpenLoadingScreenImages, setCampaigns } =
  rftSlice.actions;

export default rftSlice.reducer;
